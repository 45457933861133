@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(12, 12, 12, 0.87);
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes glowing {
  0% {
    color: #fb842b;
    text-shadow: 0 0 5px #fb842b, 0 0 5px #fb842b, 0 0 5px #fb842b;
  }
  50% {
    color: #ffa500;
    text-shadow: 0 0 5px #ffa500, 0 0 5px #ffa500, 0 0 5px #ffa500;
  }
  100% {
    color: #fb842b;
    text-shadow: 0 0 5px #fb842b, 0 0 5px #fb842b, 0 0 5px #fb842b;
  }
}

.glow-text {
  animation: glowing 1.5s infinite alternate;
}