/* body {
  font-family: Arial, sans-serif;
  text-align: center;
} */

.qr-section {
    margin-top: 40px;
}

.qr-section img {
    width: 150px;
    cursor: pointer;
}

.center-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #000;
    border-radius: 20px;
    background-color: #f8f8f8;
    text-decoration: none;
    color: #000;
}

.center-button:hover {
    background-color: #e0e0e0;
}

.column {
    background-color: #f0f0f0;
    padding: 0px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.heading {
    margin: 10px 10px;
}