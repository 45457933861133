body {
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

.container {
    margin-top: 50px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.header img {
    margin-right: 10px;
    cursor: pointer;
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.queue-info {
    font-size: 18px;
    margin: 10px 0;
}

.highlight {
    font-size: 32px;
    font-weight: bold;
    margin: 10px 0;
}

.button {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: blue;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: darkgrey;
}